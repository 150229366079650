<template>
  <div id="dossier-admin-page">
    <h1>Dossiers</h1>
    <DataTable :value="dossiers.data" :lazy="true" :paginator="true" :rows="15" v-model:filters="filters" ref="dt"
      :totalRecords="dossiers.total" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
      @filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll" :resizableColumns="true" >

      <template #header>
          <div class='row'>
              <div class='col-sm-3 text-end'>
                  <input class="form-control" type="search" :placeholder="'Zoek'" id="example-search-input" @change="onFilter()" @input="onInput()" v-model="filters['global'].value" autocomplete="off">
              </div>
          </div>
      </template>

      <Column field="created_at" header="Datum" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.created_at, 'shortDate')}}
        </template>
      </Column>
      <Column field="contact" header="Cliënt" :sortable="true" sortField="contact.initials">
        <template #body="slotProps">
          {{slotProps.data.contact.full_name}}
        </template>
      </Column>
      <Column field="source.name" header="Bron" :sortable="true"></Column>
      <Column field="contact" header="Koopsom">
        <template #body="slotProps">
          
          {{formatCurrency(totalPension(slotProps.data.pension_fund_history), '€', 0)}} <br/>
        </template>
      </Column>
      <Column header="Fiscale Herkomst" :sortable="false">
        <template #body="slotProps">
          {{slotProps.data.pension_fund_history[0] ? slotProps.data.pension_fund_history[0].origin : '-'}}
        </template>
      </Column>
      <Column field="pension_type" header="Type" :sortable="true">
        <template #body="slotProps">
          {{slotProps.data.pension_type ? slotProps.data.pension_type.replace(/^./, slotProps.data.pension_type[0].toUpperCase()).replace('hooglaag', 'Hoog/Laag') : ""}}
        </template></Column>
      <Column field="user" header="Adviseur" :sortable="true" sortField="user.first_name">
        <template #body="slotProps">
          <template v-if="slotProps.data.user">
            {{slotProps.data.user.first_name[0]}}.{{slotProps.data.user.last_name}}
          </template>
        </template>
      </Column>
      <Column field="status" header="Status" :sortable="true">
        <template #body="slotProps">
          {{slotProps.data.status.replace('new', 'Nieuw').replace('Paid', 'Betaald').replace('approved', 'Geaccepteerd').replace('signed', 'Ondertekend')}}
          <br>
          {{slotProps.data.step && slotProps.data.step.action ? slotProps.data.step.action : ''}}
        </template>
      </Column>
      <Column field="dossier_number" header="Dossier" ref="dossier_number" :sortable="true">
        <template #body="slotProps">
          #{{slotProps.data.dossier_number}}
        </template>
      </Column>
      <Column field="id" header="Acties" :sortable="true">
        <template #body="slotProps">
          <div class="d-flex w-100">
            <router-link class="fa-stack" :to="`/dossier/${slotProps.data.dossier_number}`">
                <i class="fas fa-circle fa-stack-2x text-warning"></i>
                <i class="fal fa-pencil-alt fa-stack-1x fa-inverse"></i>
            </router-link>
            <a v-if="moduleName != 'Locales'" class="fa-stack" @click="deleteDossiers(slotProps.data.id)">
                <i class="fas fa-circle fa-stack-2x text-danger"></i>
                <i class="fal fa-trash-alt fa-stack-1x fa-inverse"></i>
            </a>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
archiveren

<script>
  import dossierservice from '@/services/admin/DossierService'
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import { FilterMatchMode } from 'primevue/api';

  export default {
    name: 'adminDossiers',
    components: {
      DataTable,
      Column
    },
    data() {
      return {
        dossiers: {
          'data': [],
          'total': 0
        },
        filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
        loading: true,
        lazyParams: {},
        page: 1
      }
    },
    methods: {
      getIndex() {
        this.loading = true;
        dossierservice.index(this.page, {
          lazyEvent: JSON.stringify(this.lazyParams)
        }).then(response => {
          this.dossiers = response.data
          this.loading = false;
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
        })
      },
      onPage(event) {
        this.page = event.page + 1
        this.getIndex();
      },
      onSort(event) {
        this.lazyParams = event;
        this.getIndex();
      },
      onFilter() {
        this.lazyParams.filters = this.filters;
        this.getIndex();
      },
      deleteDossiers(id) {
        if (confirm('Weet je zeker dat je dit dossier wilt archiveren?')) {
          dossierservice.delete(id).then(response => {
            this.getIndex()
            this.$toast.success('dossier is gearchiveerd')
          }).catch(error => {
            this.$toast.error('fout bij archiveren')
          })
        }
      },
      getTotalFunds(funds) {
        let total = 0
        funds.forEach(fund => {
          total += parseFloat(fund.fund)
        });
        return total
      },
      onInput() {
          if(this.filters['global'].value == '') {
              this.onFilter();
          }
      },
      initFilters() {
          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          }
      },
      totalPension(pensionFundHistory){
        let total = [];
        Object.entries(pensionFundHistory).forEach(([key, val]) => {
            total.push(val.fund)
        });
        return total.reduce(function(total, num){ return parseFloat(total) + parseFloat(num) }, 0);
      }
    },
    mounted() {
      this.initFilters()
      this.lazyParams = {
        first: 0,
        rows: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
        filters: this.filters
      };
      this.getIndex()
    }
  }
</script>

<style lang="css" scoped>
  .fa-stack:hover {cursor: pointer}
</style>